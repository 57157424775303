import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { createCloudinaryImageUrl } from '../../../../utils/cloudinaryUtils';
import { settings } from '../../../../sites';
import { contentfulContentTypes } from '../../../../constants';

const createMeta = (page, seoTitle, seoCanonical, parentPage) => {
  const tags = [];
  const seoFields = get(page, 'seoFields');

  const currentLocale = get(page, 'languageCode', settings.localization.defaultLanguageCode);
  const siteMetadataDescriptions = JSON.parse(get(parentPage, 'site.siteMetadata.description'));
  const defaultSiteMetadataDescription = get(siteMetadataDescriptions, currentLocale);

  const title = isEmpty(get(seoFields, 'ogTitle')) ? seoTitle : get(seoFields, 'ogTitle');
  if (!isEmpty(title)) {
    tags.push({ property: 'og:title', content: title });
    tags.push({ name: 'twitter:title', content: title });
  }

  const metaDescription = isEmpty(get(seoFields, 'metaDescription'))
    ? get(page, 'shortDescription')
    : get(seoFields, 'metaDescription');

  if (!isEmpty(metaDescription)) {
    tags.push({ name: 'description', content: metaDescription });
  }

  if (isEmpty(metaDescription)) {
    tags.push({ name: 'description', content: defaultSiteMetadataDescription });
  }

  const description = isEmpty(get(seoFields, 'ogDescription')) ? metaDescription : get(seoFields, 'ogDescription');

  if (!isEmpty(description)) {
    tags.push({ property: 'og:description', content: description });
    tags.push({ name: 'twitter:description', content: description });
  }

  if (isEmpty(description)) {
    tags.push({ property: 'og:description', content: defaultSiteMetadataDescription });
    tags.push({ name: 'twitter:description', content: defaultSiteMetadataDescription });
  }

  if (!isEmpty(seoCanonical)) {
    tags.push({ property: 'og:url', content: seoCanonical });
  }

  let ogImageTransformation = 'OgImage-MD-MD';

  let image = isEmpty(get(seoFields, 'ogCloudinaryImage.0.public_id'))
    ? get(page, 'heroImage.cloudinaryImage.0')
    : get(seoFields, 'ogCloudinaryImage.0');

  if (get(page, 'internal.type') === contentfulContentTypes.CampaignPage) {
    image = get(page, 'featuredProducts.relatedProduct.heroImage.cloudinaryImage.0');
    ogImageTransformation = 'OgProductImage-LG-MD';
  }
  if (!isEmpty(image)) {
    const publicId = get(image, 'public_id');
    if (!isEmpty(publicId)) {
      tags.push({ property: 'og:image', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({ property: 'og:image:url', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({
        property: 'og:image:secure_url',
        content: createCloudinaryImageUrl(ogImageTransformation, publicId),
      });
      tags.push({ property: 'og:image:width', content: '1200px' });
      tags.push({ property: 'og:image:height', content: '627px' });
    }
  }

  return tags;
};

export default createMeta;
