/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { get, isEmpty, map, replace } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import createMeta from './createMeta';

function ViinimaaSeoFields({ pageData }) {
  const { pathname } = useLocation();

  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          siteUrl
          defaultLanguage
        }
      }
    }
  `);

  const siteUrl = get(query, 'site.siteMetadata.siteUrl');

  const siteTitle = get(pageData, 'mainTheme.seoTitle');

  const _seoSchema = get(pageData, 'seoFields.schema.schema');
  const seoSchema = replace(replace(_seoSchema, '<script>', ''), '</script>', '');

  const seoTitle = get(pageData, 'seoFields.pageTitle');
  const seoCanonical = `${siteUrl}${pathname}`;
  const pageTitle = isEmpty(get(pageData, 'title')) ? get(pageData, 'menuTitle') : get(pageData, 'title');
  const languageVersions = get(pageData, 'languageVersions');
  const defaultTitle = siteTitle;
  const selectedLanguageCode = get(pageData, 'languageCode');

  let title = pageTitle;
  let titleTemplate = `%s | ${siteTitle}`;

  if (isEmpty(siteTitle)) {
    titleTemplate = `%s`;
  }

  if (!isEmpty(seoTitle)) {
    title = seoTitle;
  }

  const meta = createMeta(pageData, isEmpty(title) ? defaultTitle : title, seoCanonical, query);

  return (
    <Helmet title={title} titleTemplate={titleTemplate} defaultTitle={defaultTitle} meta={meta} defer={false}>
      <html lang={selectedLanguageCode} />
      <link rel="canonical" href={seoCanonical} />
      {map(languageVersions, version => {
        return (
          <link
            rel="alternate"
            hrefLang={version.languageCode}
            href={siteUrl + version.fullPath}
            key={version.languageCode}
          />
        );
      })}
      {!isEmpty(seoSchema) && <script type="application/ld+json">{seoSchema}</script>}
    </Helmet>
  );
}

export const query = graphql`
  fragment SeoDataFragment on ContentfulSeoData {
    pageTitle
    ogTitle
    ogDescription
    metaDescription
    ogCloudinaryImage {
      public_id
    }
    schema {
      schema
      id
    }
  }
`;

ViinimaaSeoFields.propTypes = {
  pageData: PropTypes.object,
};

ViinimaaSeoFields.defaultProps = {
  pageData: null,
};

export default ViinimaaSeoFields;
